import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import RightSideTest from '../components/rightSideTest';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox';
import GuiButton from '../components/GuiButton';
import * as config from '../config.json'
class EditFunctionData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        retailerName: '',
        retailerRegisterName: '',
        shopAddress: '',
        venueName: '',
        shopGSTNumber: '',
        retailerEmail: '',
        retailerContactPerson: '',
        retailerContactPersonNumber: '',
        retailerContactCountryCode: '',
        retailerContactPersonEmail: '',
        retailerWebsiteURL: '',
        mallId: '',
        isActive: '',
        isDeleted: '',
        retailerId: '',
        output: '',
        Function_Index: '',
        Function_Store_Name: '',
        Main_Store_Name: '',
        t_mode: '',
        t_search_mode: '',
        t_search_text: '',
        t_subtract_from_time: '',
        t_add_to_time: '',
        t_regex: '',
        t_selector: '',
        d_mode: '',
        d_search_mode: '',
        d_search_text: '',
        d_subtract_from_date: '',
        d_add_to_date: '',
        d_regex: '',
        d_select_date: '',
        d_day: '',
        i_method: '',
        i_search_text: '',
        i_search_regex: '',
        i_search_regex_2: '',
        i_start_index: '',
        i_end_index: '',
        i_invoice_value: '',
        i_split_on: '',
        i_value_selector: '',
        i_subtract_from_index: '',
        i_add_to_index: '',
        i_regex_value_selector: '',
        i_overall_regex_selector: '',
        tot_search_text: '',
        tot_start_index: '',
        tot_end_index: '',
        tot_subtract_from_total: '',
        tot_add_to_total: '',
        tot_use_max: '',
        tot_search_text2: '',
        tot_subtract_from_total2: '',
        tot_add_to_total2: '',
        tot_max_limit: '',
        tot_min_limit: '',
        tot_method: '',
        remove_backprint:'',
        b_keyword:'',
        error:'',
        message:'',
    }
  }
  componentDidMount = () => {
    this.getFunction()
  }
  getFunction = () => {
    let functionId = this.props.match.params.functionId
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.function}/${functionId}`, header)
    .then(res => {
        let data = res.data.data.function;
        this.setState({...data});
    })
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  time_check = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.append("image_to_text", image_to_text);
    formData.set("mall_id", id);
    formData.set("Function_Index", this.state.Function_Index);
    formData.set("Function_Store_Name", this.state.Function_Store_Name);
    formData.set("Main_Store_Name", this.state.Main_Store_Name);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.time_check}`, formData, header)
      // .then(res => console.log(res.data.data.time_check))
      .then(res => (res.data.data.time_check))
      .then(output => this.setState({
        output: output,
        Function_Index: '',
        Function_Store_Name: '',
        Main_Store_Name: '',
      }))
  }
  proceed = () => {
    this.setState({error : ''})
    let data = this.state;
    let formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        formData.append(`${key}`, value);
    }
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    let functionId = this.props.match.params.functionId
    axios.put(`${config.baseurl}${id}/${config.function}/${functionId}`, formData, header)
    .then(res => {
        console.log(res.data.data)
        this.setState({message : res.data.message});
        setTimeout(() => {
          this.props.history.push('/guidesk/function_index');
          this.setState({message : ''});
        }, 2000);
    })
    .catch(error => {
        if(error.response){
            this.setState({error : error.response.data.message})
        }
    })
  }
  render() {
    return (
      <div>
        <h3>Update Function Data</h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <form>
                <label style={{fontWeight:'800'}}>Retailer Info:</label>
                <div class="row">
                    <div class="col-6">
                      <InputBox name='retailerName' disable={true} value={this.state.retailerName} onChange={this.setTitle} />
                      <InputBox name='retailerRegisterName' disable={true} value={this.state.retailerRegisterName} onChange={this.setTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox name='shopAddress' disable={true} value={this.state.shopAddress} onChange={this.setTitle} />
                      <InputBox name='venueName' disable={true} value={this.state.venueName} onChange={this.setTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox name='shopGSTNumber' disable={true} value={this.state.shopGSTNumber} onChange={this.setTitle} />
                      <InputBox name='retailerEmail' disable={true} value={this.state.retailerEmail} onChange={this.setTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox name='retailerContactPerson' disable={true} value={this.state.retailerContactPerson} onChange={this.setTitle} />
                      <InputBox name='retailerContactPersonNumber' disable={true} value={this.state.retailerContactPersonNumber} onChange={this.setTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox name='retailerContactCountryCode' disable={true} value={this.state.retailerContactCountryCode} onChange={this.setTitle} />
                      <InputBox name='retailerWebsiteURL' disable={true} value={this.state.retailerWebsiteURL} onChange={this.setTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox name='mallId' disable={true} value={this.state.mallId} onChange={this.setTitle} />
                      <InputBox name='isActive' disable={true} value={this.state.isActive} onChange={this.setTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox name='isDeleted' disable={true} value={this.state.isDeleted} onChange={this.setTitle} />
                      <InputBox name='retailerId' disable={true} value={this.state.retailerId} onChange={this.setTitle} />
                    </div>
                </div>
                <label style={{fontWeight:'800'}}>Function Index</label>
                  <div class="row">
                      <div class="col-6">
                        <InputBox name='Function_Index' value={this.state.Function_Index} onChange={this.setTitle} />
                        <InputBox name='Function_Store_Name' value={this.state.Function_Store_Name} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='Main_Store_Name' value={this.state.Main_Store_Name} onChange={this.setTitle} />
                      </div>
                  </div>
                <div>
                </div>
                <label style={{fontWeight:'800'}}>Function Time:</label>
                  <div class="row">
                      <div class="col-6">
                        <InputBox name='t_mode' value={this.state.t_mode} onChange={this.setTitle} />
                        <InputBox name='t_search_text' value={this.state.t_search_text} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='t_search_mode' value={this.state.t_search_mode} onChange={this.setTitle} />
                        <InputBox name='t_subtract_from_time' value={this.state.t_subtract_from_time} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='t_add_to_time' value={this.state.t_add_to_time} onChange={this.setTitle} />
                        <InputBox name='t_selector' value={this.state.t_selector} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='t_regex' value={this.state.t_regex} onChange={this.setTitle} />
                      </div>
                  </div>
                <label style={{fontWeight:'800'}}>Function Date:</label>
                  <div class="row">
                      <div class="col-6">
                        <InputBox name='d_mode' value={this.state.d_mode} onChange={this.setTitle} />
                        <InputBox name='d_search_text' value={this.state.d_search_text} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='d_search_mode' value={this.state.d_search_mode} onChange={this.setTitle} />
                        <InputBox name='d_subtract_from_date' value={this.state.d_subtract_from_date} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='d_add_to_date' value={this.state.d_add_to_date} onChange={this.setTitle} />
                        <InputBox name='d_select_date' value={this.state.d_select_date} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='d_regex' value={this.state.d_regex} onChange={this.setTitle} />
                        <InputBox name='d_day' value={this.state.d_day} onChange={this.setTitle} />
                      </div>
                </div>
                <label style={{fontWeight:'800'}}>Function Invoice:</label>
                  <div class="row">
                      <div class="col-6">
                        <InputBox name='i_method' value={this.state.i_method} onChange={this.setTitle} />
                        <InputBox name='i_search_regex' value={this.state.i_search_regex} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='i_search_text' value={this.state.i_search_text} onChange={this.setTitle} />
                        <InputBox name='i_search_regex_2' value={this.state.i_search_regex_2} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='i_start_index' value={this.state.i_start_index} onChange={this.setTitle} />
                        <InputBox name='i_invoice_value' value={this.state.i_invoice_value} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='i_end_index' value={this.state.i_end_index} onChange={this.setTitle} />
                        <InputBox name='i_split_on' value={this.state.i_split_on} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='i_value_selector' value={this.state.i_value_selector} onChange={this.setTitle} />
                        <InputBox name='i_add_to_index' value={this.state.i_add_to_index} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='i_subtract_from_index' value={this.state.i_subtract_from_index} onChange={this.setTitle} />
                        <InputBox name='i_regex_value_selector' value={this.state.i_regex_value_selector} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='i_overall_regex_selector' value={this.state.i_overall_regex_selector} onChange={this.setTitle} />
                      </div>
                </div>
                <label style={{fontWeight:'800'}}>Function Total:</label>
                  <div class="row">
                      <div class="col-6">
                        <InputBox name='tot_search_text' value={this.state.tot_search_text} onChange={this.setTitle} />
                        <InputBox name='tot_end_index' value={this.state.tot_end_index} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='tot_start_index' value={this.state.tot_start_index} onChange={this.setTitle} />
                        <InputBox name='tot_subtract_from_total' value={this.state.tot_subtract_from_total} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='tot_add_to_total' value={this.state.tot_add_to_total} onChange={this.setTitle} />
                        <InputBox name='tot_search_text2' value={this.state.tot_search_text2} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='tot_use_max' value={this.state.tot_use_max} onChange={this.setTitle} />
                        <InputBox name='tot_subtract_from_total2' value={this.state.tot_subtract_from_total2} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='tot_add_to_total2' value={this.state.tot_add_to_total2} onChange={this.setTitle} />
                        <InputBox name='tot_min_limit' value={this.state.tot_min_limit} onChange={this.setTitle} />
                      </div>
                      <div class="col-6">
                        <InputBox name='tot_max_limit' value={this.state.tot_max_limit} onChange={this.setTitle} />
                        <InputBox name='tot_method' value={this.state.tot_method} onChange={this.setTitle} />
                      </div>
                </div>
                <label style={{fontWeight:'800'}}>Function Final:</label>
                  <div class="row">
                      <div class="col-6">
                        <InputBox name='remove_backprint' value={this.state.remove_backprint} onChange={this.setTitle} />
                        <InputBox name='b_keyword' value={this.state.b_keyword} onChange={this.setTitle} />
                      </div>
                </div>
            </form>
            <div style={{color:'red',fontWeight:300}}>{this.state.error.toUpperCase()}</div>
            <div style={{color:'green',fontWeight:300}}>{this.state.message.toUpperCase()}</div>
            <button className={` btn btn-danger`} style={{ margin: 10, width: '20%' }} onClick={() => this.props.history.push('/guidesk/function_index')} >Cancel</button>
            <button className={` btn btn-info`} style={{ margin: 10, width: '20%' }} onClick={this.proceed} >Submit</button>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(EditFunctionData);