import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox';
import GuiButton from '../components/GuiButton';
import * as config from '../config.json'
class FunctionDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      function_list: [],
      output: '',
      tot_search_text: '',
      tot_start_index: '',
      tot_end_index: '',
      tot_substract_from_total: '',
      tot_add_to_total: '',
      tot_use_max: '',
      tot_search_text_2: '',
      tot_substract_from_total_2: '',
      tot_add_to_total_2: '',
      tot_max_limit: '',
      tot_min_limit: '',
      tot_method: '',
    }
  }
  componentDidMount = () => {
    this.getFunction()
  }
  getFunction = () => {
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.function}`, header)
    // .then(res => console.log(res.data))
    // .then(res => res.data)
    // .then(function_list => this.setState(
    //   {
    //     function_list: function_list
    //   }
    // ))
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  date_check = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.append("image_to_text", image_to_text);
    formData.set("mall_id", id);
    formData.set("search_text_total", this.state.tot_search_text);
    formData.set("start_index_total", this.state.tot_start_index);
    formData.set("end_index_total", this.state.tot_end_index);
    formData.set("subtract_from_total", this.state.tot_substract_from_total);
    formData.set("add_to_total", this.state.tot_add_to_total);
    formData.set("use_max", this.state.tot_use_max);
    formData.set("search_text2", this.state.tot_search_text_2);
    formData.set("subtract_from_total2", this.state.tot_substract_from_total_2);
    formData.set("add_to_total2", this.state.tot_add_to_total_2);
    formData.set("max_limit", this.state.tot_max_limit);
    formData.set("min_limit", this.state.tot_min_limit);
    formData.set("tot_method", this.state.tot_method);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.invoice_total_check}`, formData, header)
      // .then(res => console.log(res.data.data.time_check))
      .then(res => (res.data.data.invoice_total_check))
      .then(output => this.setState({
        output: output,
        tot_search_text: '',
        tot_start_index: '',
        tot_end_index: '',
        tot_substract_from_total: '',
        tot_add_to_total: '',
        tot_use_max: '',
        tot_search_text_2: '',
        tot_substract_from_total_2: '',
        tot_add_to_total_2: '',
        tot_max_limit: '',
        tot_min_limit: '',
        tot_method: '',
      }))
  }
  proceed = () => {
    this.props.history.push('/guidesk/function_total')
  }
  render() {
    const columns =
      [
        {
          Header: 'ID',
          accessor: 'id',
          sortable: false,
          style: {
            textAlign: 'left'
          }
        },
        {
          Header: 'Name',
          accessor: 'name',
          filterable: true,
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'City',
          accessor: 'address.city'
        },
      ]
    return (
      <div>
        <h3>Function_data_total </h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.function_list}
                showPagination={true}
              >
              </Ktable>
            </div>
            <form>
              <div>
                <InputBox name='tot_search_text' value={this.state.tot_search_text} onChange={this.setTitle} />
                <InputBox name='tot_start_index' value={this.state.tot_start_index} onChange={this.setTitle} />
                <InputBox name='tot_end_index' value={this.state.tot_end_index} onChange={this.setTitle} />
                <InputBox name='tot_substract_from_total' value={this.state.tot_substract_from_total} onChange={this.setTitle} />
              </div>
              <div>
                <InputBox name='tot_add_to_total' value={this.state.tot_add_to_total} onChange={this.setTitle} />
                <InputBox name='tot_use_max' value={this.state.tot_use_max} onChange={this.setTitle} />
                <InputBox name='tot_search_text_2' value={this.state.tot_search_text_2} onChange={this.setTitle} />
                <InputBox name='tot_substract_from_total_2' value={this.state.tot_substract_from_total_2} onChange={this.setTitle} />
              </div>
              <div>
                <InputBox name='tot_add_to_total_2' value={this.state.tot_add_to_total_2} onChange={this.setTitle} />
                <InputBox name='tot_max_limit' value={this.state.tot_max_limit} onChange={this.setTitle} />
                <InputBox name='tot_min_limit' value={this.state.tot_min_limit} onChange={this.setTitle} />
                <InputBox name='tot_method' value={this.state.tot_method} onChange={this.setTitle} />
              </div>
              {/* <button style={form_style} type="submit" id='' className="btn btn-success" >Enter</button> */}
            </form>
            <GuiButton value='back to home' action={() => this.proceed()} />
          </div>
          {/* <RightSideTest name='Run' output={this.state.output} action={() => this.date_check()}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default FunctionDate