import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import GuiButton from '../components/GuiButton';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader'
import * as config from '../config.json'
import InputBox from '../components/inputBox'

class StoreDictionery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dictionary_list: [],
      output: '',
      editData:{},
      mallId: localStorage.getItem('mall_id'),
      date_index:'',
      store_name:'',
      keyword:'',
      add:false,
      error:'',
    }
  }
  componentDidMount = () => {
    this.getDict()
  }

  getDict = () => {
    const id = localStorage.getItem('mall_id')
    // const mall_name = this.props.match.params.name;
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.dictionary}`, header)
      .then(res => (res.data.data.dictionary_list))
      .then(dictionary_list => this.setState(
        {
          dictionary_list: dictionary_list
        }
      ))
  }
  dictionary_check = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.set("image_to_text", image_to_text);
    formData.set("mall_id", id);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.dictionary_check}`, formData, header)
      .then(res => (res.data.data.dictionary_check))
      .then(output => this.setState({
        output: output
      }))
  }

  editRow = (data) => {
    this.setState({ editData: data})
  }



  setAddTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  createDict = (e) => {
    this.setState({error : ''})
    e.preventDefault()
    const mall_id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    if (this.state.date_index && this.state.store_name && this.state.keyword) {
      let formData = new FormData();
      formData.append("date_index", this.state.date_index);
      formData.append("store_name", this.state.store_name);
      formData.append("keyword", this.state.keyword);
      axios.post(`${config.baseurl}${mall_id}/${config.dictionary}`, formData, header)
        .then(res => {
          this.setState({message: res.data.message,add: false})
          setTimeout(() => {
            this.setState({
              editData:{},
              value: '',
              message: '',
              date_index:'',
              store_name:'',
              keyword:'',
            })
          }, 2000);
          this.getDict()
        })
        .catch(error => {
            if(error.response){
                this.setState({error : error.response.data.message})
            }
        })
    }
  }


  updateDict = (e) => {
    this.setState({error : ''})
    e.preventDefault()
    const mall_id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    if (this.state.editData.Data_Index && this.state.editData.Keyword && this.state.editData.Store_Name) {
      let formData = new FormData();
      formData.append("date_index", this.state.editData.Data_Index);
      formData.append("store_name", this.state.editData.Keyword);
      formData.append("keyword", this.state.editData.Store_Name);
      axios.put(`${config.baseurl}${mall_id}/${config.dictionary}/${this.state.editData.id}`, formData, header)
        .then(res => {
          this.setState({message: res.data.message})
            setTimeout(() => {
              this.setState({
                editData:{},
                value: '',
                message: '',
                date_index:'',
                store_name:'',
                keyword:'',
              })
            }, 2000);
          this.getDict()
        })
        .catch(error => {
            if(error.response){
                this.setState({error : error.response.data.message})
            }
        })
    }
  }

  setTitle = (e) => {
    let data = this.state.editData
    data[e.target.name] = e.target.value
    console.log(data);
    this.setState({...this.state.editData})
  }

  render() {
    const columns =
      [
        {
          Header: 'Index',
          accessor: 'Data_Index',
          sortable: false,
          style: {
            textAlign: 'left'
          }
        },
        {
          Header: 'Keyword',
          accessor: 'Keyword',
          filterable: true,
        },
        {
          Header: 'Store Name',
          accessor: 'Store_Name',
          filterable: true,
        },
        {
          Header: 'Action',
          Cell: props => {
            return (
              <div>
                <button className='btn btn-info for_size' onClick={() => this.editRow(props.original)}>Update</button>
              </div>
            )
          }
        },
      ]
      const form_style = {
        'width': '20%',
        'display': 'inline',
        'margin': '2%',
      }
        
    return (
      <div>
        <h3>Store Dictionary </h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.dictionary_list}
                showPagination={true}
                showPaginationBottom={false}
                showPaginationTop={true}
              >
              </Ktable>
            </div>
            <div>
              {
                Object.keys(this.state.editData).length > 0
                ?
                <form onSubmit={this.updateDict}>
                  <div class="row">
                    <div class="col-6">
                      <InputBox placeholder='func_name' name="Data_Index" value={this.state.editData.Data_Index} onChange={this.setTitle} />
                      <InputBox placeholder='value' name="Keyword" value={this.state.editData.Keyword} onChange={this.setTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox placeholder='keyword' name="Store_Name" value={this.state.editData.Store_Name} onChange={this.setTitle} />
                    </div>
                  </div>
                  <button style={form_style} type="submit" id='' className="btn btn-success">Save</button>
                </form>
                :
                <></>
              }
            </div>
            <div>
              {
                this.state.add
                ?
                <form onSubmit={this.createDict}>
                  <div class="row">
                    <div class="col-6">
                      <InputBox placeholder='func_name' name="date_index" value={this.state.date_index} onChange={this.setAddTitle} />
                      <InputBox placeholder='value' name="keyword" value={this.state.keyword} onChange={this.setAddTitle} />
                    </div>
                    <div class="col-6">
                      <InputBox placeholder='keyword' name="store_name" value={this.state.store_name} onChange={this.setAddTitle} />
                    </div>
                  </div>
                  <button style={form_style} type="submit" id='' className="btn btn-success">ADD</button>
                </form>
                :
                <></>
              }
            </div>
            <div className='text-success'>{this.state.message}</div>
            <div style={{color:'red',fontWeight:300}}>{this.state.error.toUpperCase()}</div>
            <button style={{marginTop: '3%', marginRight: '1%',}} className="btn btn-info" onClick={() => this.setState({add: true})}>Add Dictionary</button>
            <button style={{marginTop: '3%',}} className="btn btn-info" onClick={() => this.props.history.push(`/guidesk/mall/${this.state.mallId}`)}>Go Back</button>
          </div>
          {/* <RightSideTest name='match dictionary' output={this.state.output} action={() => this.dictionary_check()}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default StoreDictionery