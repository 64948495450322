import React from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import Login from '../pages/login'
import GuiDesk from '../pages/guiDesk'
import NotFound from '../pages/notFound'
import './App.css';
import authHeader from '../services/authHeader'
class App extends React.Component {
  requireLogin = (to, from, next) => {
    if (to.meta.auth) {
      if (authHeader().Authorization != null) {
        next();
      }
      next.redirect('/login');
    }
    else {
      next();
    }
  };
  render() {
    return (
      <div className="App">
        <Router>
          <GuardProvider guards={[this.requireLogin]}>
            <Switch>
              <GuardedRoute path="/" exact component={Login} />
              <GuardedRoute path="/login" component={Login} />
              <GuardedRoute path="/guidesk" component={GuiDesk} meta={{ auth: true }} />
              <GuardedRoute path="*" component={NotFound} />
              {/* <Route path='/logout' component={Logout} /> */}
            </Switch>
          </GuardProvider>
        </Router>
      </div>
    );
  }
}
export default App;
