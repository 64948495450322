import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import GuiButton from '../components/GuiButton';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox'
import * as config from '../config.json'

class UpdateAddress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address_list: [],
      sr_no: '',
      keyword: '',
      value: '',
      message: '',
      output: '',
    }
  }
  componentDidMount = () => {
    this.getAddress()
  }
  getAddress = () => {
    const id = localStorage.getItem('mall_id')
    // const mall_name = this.props.match.params.name;
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.address}`, header)
      .then(res => (res.data.data.address_list))
      .then(address_list => this.setState(
        {
          address_list: address_list
        }
      ))
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  createAddress = (e) => {
    e.preventDefault()
    const mall_id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.set("level", this.state.sr_no);
    formData.set("index_no", this.state.keyword);
    formData.set("value", this.state.value);
    let header = {
      headers: authHeader()
    };
    if (this.state.sr_no && this.state.keyword && this.state.value) {
      axios.post(`${config.baseurl}${mall_id}/${config.address}`, formData, header)
        .then(res => (res.data.message))
        .then(message => {
          this.setState({
            message: message
          },
            this.setState({
              sr_no: '',
              keyword: '',
              value: '',
              message: '',
            })
          );
          this.getAddress()
        }
        )
    }
  }
  AddressCheck = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.set("image_to_text", image_to_text);
    formData.set("mall_id", id);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.address_check}`, formData, header)
      // .then(res=>console.log(res.data.data.address_check))
      .then(res => (res.data.data.address_check))
      .then(output => this.setState({
        output: output
      }))
      .catch(error => {
        this.props.history.push('/login')
      })
  }
  deleteAddress = (id) => {
    const mall_id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.delete(`${config.baseurl}${mall_id}/${config.address}/${id}`, header)
      .then(res => {
        this.getAddress();
      }
      )
      .catch(error => {
        this.props.history.push('/login')
      })
  }
  render() {
    const columns =
      [
        {
          Header: 'action',
          Cell: props => {
            return (
              <div>
                <button className='btn btn-danger for_size' onClick={() => this.deleteAddress(props.original.id)}>Delete</button>
              </div>
            )
          }
        },
        {
          Header: 'index',
          accessor: 'Index_no',
          sortable: false,
          style: {
            textAlign: 'left'
          }
        },
        {
          Header: 'Level',
          accessor: 'Level',
          filterable: true,
        },
        {
          Header: 'Value',
          accessor: 'Value',
        },
      ]
    const form_style = {
      'width': '20%',
      'display': 'inline',
      'margin': '2%',
    }
    return (
      <div>
        <h3>update_address </h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.address_list}
                showPagination={true}
                showPaginationBottom={false}
                showPaginationTop={true}
              >
              </Ktable>
            </div>
            <form onSubmit={this.createAddress}>
              <div>
                <InputBox name='sr_no' value={this.state.sr_no} onChange={this.setTitle} />
                <InputBox name='keyword' value={this.state.keyword} onChange={this.setTitle} />
                <InputBox name='value' value={this.state.value} onChange={this.setTitle} />
              </div>
              <button style={form_style} type="submit" id='' className="btn btn-success" >Enter</button>
            </form>
            <GuiButton value='Proceed' action={() => this.props.history.push('/guidesk/storedictionery')} />
            <span className='text-success'>{this.state.message}</span>
          </div>
          {/* <RightSideTest name='address check' output={this.state.output} action={() => this.AddressCheck()}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default UpdateAddress