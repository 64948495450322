import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import GuiButton from '../components/GuiButton';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox'
import * as config from '../config.json'
import '../style.scss';

class OutputDataDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mallId: localStorage.getItem('mall_id'),
            outputData: JSON.parse(localStorage.getItem('output')),
            message: '',
            output: '',
        }
      }
      componentDidMount = () => {
      }
      deleteAddress = () => {
        const mall_id = localStorage.getItem('mall_id')
        let header = {
          headers: authHeader()
        };
        axios.delete(`${config.baseurl}${mall_id}/${config.output}/${this.state.outputData.id}`, header)
          .then(res => {
            this.props.history.push(`/guidesk/outputData`);
            this.setState({outputData:null})
            // this.getAddress();
          }
          )
          .catch(error => {
            this.props.history.push('/login')
          })
      }
      render() {
        return (
          <div>
            <div style={{display: 'flex',flexWrap: 'wrap',justifyContent: 'end',}}>
                <h3>Output Details</h3>
                <button style={{marginTop: '1%',height:'40px'}} className="btn btn-info" onClick={() => this.props.history.push(`/guidesk/outputData`)}>Go Back</button>
            </div>
            <div style={{ padding: 15, position: "relative" }}>
              <div>
                <div>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="header">id</td>
                                <td>{this.state.outputData.id}</td>
                            </tr>
                            <tr>
                                <td class="header">base64_data</td>
                                <td>{this.state.outputData.base64_data}</td>
                            </tr>
                            <tr>
                                <td class="header">bill_date</td>
                                <td>{this.state.outputData.bill_date}</td>
                            </tr>
                            <tr>
                                <td class="header">bill_number</td>
                                <td>{this.state.outputData.bill_number}</td>
                            </tr>
                            <tr>
                                <td class="header">bill_time</td>
                                <td>{this.state.outputData.bill_time}</td>
                            </tr>
                            <tr>
                                <td class="header">bill_total</td>
                                <td>{this.state.outputData.bill_total}</td>
                            </tr>
                            <tr>
                                <td class="header">cleaned_data</td>
                                <td>{this.state.outputData.cleaned_data}</td>
                            </tr>
                            <tr>
                                <td class="header">database_gcp_cleaning_time</td>
                                <td>{this.state.outputData.database_gcp_cleaning_time}</td>
                            </tr>
                            <tr>
                                <td class="header">database_time</td>
                                <td>{this.state.outputData.database_time}</td>
                            </tr>
                            <tr>
                                <td class="header">date_errorCode</td>
                                <td>{this.state.outputData.date_errorCode}</td>
                            </tr>
                            <tr>
                                <td class="header">file_location</td>
                                <td>{this.state.outputData.file_location}</td>
                            </tr>
                            <tr>
                                <td class="header">final_errorCode</td>
                                <td>{this.state.outputData.final_errorCode}</td>
                            </tr>
                            <tr>
                                <td class="header">img_file_to_text_time</td>
                                <td>{this.state.outputData.img_file_to_text_time}</td>
                            </tr>
                            <tr>
                                <td class="header">invoice_number_errorCode</td>
                                <td>{this.state.outputData.invoice_number_errorCode}</td>
                            </tr>
                            <tr>
                                <td class="header">raw_data</td>
                                <td>{this.state.outputData.raw_data}</td>
                            </tr>
                            <tr>
                                <td class="header">store_name</td>
                                <td>{this.state.outputData.store_name}</td>
                            </tr>
                            <tr>
                                <td class="header">total_errorCode</td>
                                <td>{this.state.outputData.total_errorCode}</td>
                            </tr>
                            <tr>
                                <td class="header">time_errorCode</td>
                                <td>{this.state.outputData.time_errorCode}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <span className='text-success'>{this.state.message}</span>
                <button style={{marginTop: '3%',display: 'none'}} className="btn btn-info" onClick={() => this.deleteAddress()}>Delete</button>
                <button style={{marginTop: '3%',}} className="btn btn-info" onClick={() => this.props.history.push(`/guidesk/outputData`)}>Go Back</button>
              </div>
            </div>
          </div>
        )
      }
}
export default OutputDataDetails