import axios from "axios";
import authHeader from './authHeader'
import * as config from '../config.json'
async function openMall() {
  let url = `${config.baseurl}${config.mall}`;
  let header = {
    headers: authHeader()
  };
  const response = await axios.get(url, header)
  return response;
}
export const mallSelect = {
  openMall,
};