import React from 'react';
import GuiButton from '../components/GuiButton';
import Ktable from '../components/dataTable/K_table'
import { mallSelect } from '../services/mallSelect';
class MallSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mall_list: [],
    }
  }
  componentDidMount = () => {
    mallSelect.openMall()
      .then(res => res.data.data.mall_list)
      .then(mall_list => this.setState(
        {
          mall_list: mall_list
        }
      ))
      .catch(error => {
        this.props.history.push('/login')
      })
  }
  newMall = () => {
    this.props.history.push('/guidesk/addnewmall')
  }
  openMall = (id, name) => {
    this.props.history.push(`/guidesk/mall/${id}`)
    localStorage.setItem('mall_id', id)
    localStorage.setItem('mall_name', name)
    console.log(name);
  }
  render() {
    const columns =
      [
        {
          Header: 'Mall Name',
          accessor: 'mall_name',
          filterable: true,
        },
        {
          Header: 'Mall DB',
          accessor: 'mall_db',
        },
        {
          Header: 'action',
          Cell: props => {
            return (
              <div>
                <button className='btn btn-warning for_size' onClick={() => this.openMall(props.original.id, props.original.mall_name)}>Open</button>
              </div>
            )
          }
        }
      ]
    return (
      <div style={{ padding: 15 }}>
        <h3>Mall Select </h3>
        <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
          <Ktable
            columns={columns}
            data={this.state.mall_list}
            showPagination={true}
            showPaginationBottom={false}
            showPaginationTop={true}
          >
          </Ktable>
        </div>
        {/* <GuiButton value='add new mall' action={() => this.newMall()} /> */}
      </div>
    )
  }
}
export default MallSelect