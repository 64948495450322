import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader'
import GuiButton from '../components/GuiButton';
import * as config from '../config.json'
import InputBox from '../components/inputBox'
class AddressCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address_list: [],
      output: '',
      editData:{},
      mallId: localStorage.getItem('mall_id'),
      level:'',
      index_no:'',
      value:'',
      add: false,
      error:'',
    }
  }
  componentDidMount = () => {
    this.getAddress();
  }


  getAddress = () => {
    const id = localStorage.getItem('mall_id')
    // const mall_name = this.props.match.params.name;
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.address}`, header)
      // .then(res=>console.log(res.data.data.address_list))
      .then(res => (res.data.data.address_list))
      .then(address_list => this.setState(
        {
          address_list: address_list
        }
      ))
      .catch(error => {
        this.props.history.push('/login')
      })
  }

  AddressCheck = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.set("image_to_text", image_to_text);
    formData.set("mall_id", id);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.address_check}`, formData, header)
      // .then(res=>console.log(res.data.data.address_check))
      .then(res => (res.data.data.address_check))
      .then(output => this.setState({
        output: output
      }))
  }

  editRow = (data) => {
    this.setState({ editData: data})
  }


  setTitle = (e) => {
    let data = this.state.editData
    data[e.target.name] = e.target.value
    console.log(data);
    this.setState({...this.state.editData})
  }

  setAddTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  createAddress = (e) => {
    this.setState({error : ''})
    e.preventDefault()
    const mall_id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    if (this.state.level && this.state.index_no && this.state.value) {
      let formData = new FormData();
      formData.set("level", this.state.level);
      formData.set("index_no", this.state.index_no);
      formData.set("value", this.state.value);
      axios.post(`${config.baseurl}${mall_id}/${config.address}`, formData, header)
        .then(res => (res.data.message))
        .then(message => {
          this.setState({message: message, add: false})
          setTimeout(() => {
            this.setState({
              editData:{},
              level:'',
              index_no:'',
              value:'',
              message: '',
            })
          }, 2000);
          this.getAddress()
        })
        .catch(error => {
            if(error.response){
                this.setState({error : error.response.data.message})
            }
        })
    }
  }

  updateAddress = (e) => {
    this.setState({error : ''})
    e.preventDefault()
    const mall_id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    if (this.state.editData.Level && this.state.editData.Index_no && this.state.editData.Value) {
      let formData = new FormData();
      formData.set("level", this.state.editData.Level);
      formData.set("index_no", this.state.editData.Index_no);
      formData.set("value", this.state.editData.Value);
      axios.put(`${config.baseurl}${mall_id}/${config.address}/${this.state.editData.id}`, formData, header)
        .then(res => {
          this.setState({
            message: res.data.message
          })
          setTimeout(() => {
            this.setState({
              editData:{},
              message: '',
            })
          }, 2000);
          this.getAddress()
        })
        .catch(error => {
            if(error.response){
                this.setState({error : error.response.data.message})
            }
        })
    }
  }

  render() {
    const columns =
      [
        {
          Header: 'index',
          accessor: 'Index_no',
          sortable: false,
          filterable: true,
        },
        {
          Header: 'Level',
          accessor: 'Level',
          filterable: true,
        },
        {
          Header: 'Value',
          accessor: 'Value',
          filterable: true,
        },
        {
          Header: 'Action',
          Cell: props => {
            return (
              <div>
                <button className='btn btn-info for_size' onClick={() => this.editRow(props.original)}>Update</button>
              </div>
            )
          }
        },
      ]
      const form_style = {
        'width': '20%',
        'display': 'inline',
        'margin': '2%',
      }
    return (
      <div>
        <h3 style={{ padding: 15 }}>Address Check </h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.address_list}
                showPagination={true}
                showPaginationBottom={false}
                showPaginationTop={true}
              >
              </Ktable>
            </div>
            <div>
              {
                Object.keys(this.state.editData).length > 0
                ?
                <form onSubmit={this.updateAddress}>
                  <div class="row">
                    <div class="col-6">
                      <InputBox placeholder="sr_no" name='Level' value={this.state.editData.Level} onChange={this.setTitle} />
                      <InputBox placeholder="keyword" name='Index_no' value={this.state.editData.Index_no} onChange={this.setTitle} />
                    </div>
                    <div clas="col-6">
                      <InputBox placeholder="value" name='Value' value={this.state.editData.Value} onChange={this.setTitle} />
                    </div>
                  </div>
                  <button style={form_style} type="submit" id='' className="btn btn-success" >Save</button>
                </form>
                :
                <></>
              }
            </div> 
            <div>
              {
                this.state.add
                ?
                <form onSubmit={this.createAddress}>
                  <div class="row">
                    <div class="col-6">
                      <InputBox placeholder="sr_no" name='level' value={this.state.level} onChange={this.setAddTitle} />
                      <InputBox placeholder="keyword" name='index_no' value={this.state.index_no} onChange={this.setAddTitle} />
                    </div>
                    <div clas="col-6">
                      <InputBox placeholder="value" name='value' value={this.state.value} onChange={this.setAddTitle} />
                    </div>
                  </div>
                  <button style={form_style} type="submit" id='' className="btn btn-success">ADD</button>
                </form>
                :
                <></>
              }
            </div>
            <span className='text-success'>{this.state.message}</span>
            <div style={{color:'red',fontWeight:300}}>{this.state.error.toUpperCase()}</div>
            <button style={{marginTop: '3%', marginRight: '1%',}} className="btn btn-info" onClick={() => this.setState({add: true})}>Add Address</button>
            <button style={{marginTop: '3%',}} className="btn btn-info" onClick={() => this.props.history.push(`/guidesk/mall/${this.state.mallId}`)}>Go Back</button>
            {/* <GuiButton value='update adress' action={() => this.props.history.push('/guidesk/updateaddress')} /> */}
          </div>
          {/* <RightSideTest name='address check' output={this.state.output} action={() => this.AddressCheck()}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default AddressCheck