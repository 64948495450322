import React from "react";
class inputBox extends React.Component {
  render() {
    return (
      <>
      <div class="row">
        <div class="col-4">
          <label>{this.props.name}</label>
        </div>
        <div class="col-7">
          <input
            className='form-control'
            id={this.props.name}
            name={this.props.name}
            type='text'
            value={this.props.value}
            onChange={this.props.onChange}
            placeholder={this.props.name}
            style={{
              'display': 'inline',
              'margin': '1%',
            }}
            disabled={this.props.disable}
          />
        </div>
      </div>
      </>
    );
  }
};
export default inputBox;