import React from 'react';
import { loginService } from '../services/loginService'
class login extends React.Component {
  constructor() {
    super()
    // this.validator = new SimpleReactValidator( )
    this.state = {
      username: '',
      password: '',
      error:'',
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
    let formData = new FormData();
    formData.set("email", this.state.username);
    formData.set("password", this.state.password);
    if (this.state.username && this.state.password) {
      loginService
        .login(formData)
        .then(response => (response.data.data.user.token))
        .then(token => {
          this.setState({error:''})
          localStorage.setItem("user", token)
          this.props.history.push('/guidesk/mallselect')
        }
        )
        .catch(error => {
          if(error.response){
              this.setState({error : error.response.data.message})
          } else {
            this.props.history.push('/login')
          }
        })
    }
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    const form_style = {
      width: '500px',
      display: 'inline-block',
      top: '50%',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%,-50%)',
    }
    return (
      <div>
        <div style={form_style}>
          <h3>LOGIN</h3>
          {/* ,{position:'absolute'},{left:'500px'} */}
          <form action="." onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input type="text" id='username' className="form-control" name='username' value={this.state.username} onChange={this.setTitle} placeholder='username' />
              {/* {this.validator.message('user name', this.state.username, 'required|alpha')} */}
            </div>
            <div className="form-group">
              <input type="password" id='password' className="form-control" name='password' value={this.state.password} onChange={this.setTitle} placeholder='password' />
              {/* {this.validator.message('password', this.state.password, 'required|alpha_num_dash')} */}
            </div>
            <div style={{color:'red',fontWeight:300}}>{this.state.error}</div>
            <div className="form-group">
              <button style={{ width: '100%' }} type="submit" id='' className="btn btn-success" >login</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
export default login