import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import GuiButton from '../components/GuiButton';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox'
import * as config from '../config.json'

class UpdateStoreDictionery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dictionary_list: [],
      func_name: '',
      keyword: '',
      value: '',
      output: '',
      message: '',
    }
  }
  componentDidMount = () => {
    // const mall_name = this.props.match.params.name;
    this.getDict()
  }
  getDict = () => {
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.dictionary}`, header)
      .then(res => (res.data.data.dictionary_list))
      .then(dictionary_list => this.setState(
        {
          dictionary_list: dictionary_list
        }
      ))
  }
  dictionary_check = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.append("image_to_text", image_to_text);
    formData.append("mall_id", id);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.dictionary_check}`, formData, header)
      .then(res => (res.data.data.dictionary_check))
      .then(output => this.setState({
        output: output
      }))
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  createDict = (e) => {
    e.preventDefault()
    const mall_id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.append("date_index", this.state.func_name);
    formData.append("store_name", this.state.keyword);
    formData.append("keyword", this.state.value);
    let header = {
      headers: authHeader()
    };
    if (this.state.func_name && this.state.keyword && this.state.value) {
      axios.post(`${config.baseurl}${mall_id}/${config.dictionary}`, formData, header)
        .then(res => (res.data.message))
        .then(message => {
          this.setState({
            message: message
          },
            this.setState({
              func_name: '',
              keyword: '',
              value: '',
              message: '',
            })
          );
          this.getDict()
        })
    }
  }
  deleteDict = (id) => {
    const mall_id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.delete(`${config.baseurl}${mall_id}/${config.dictionary}/${id}`, header)
      .then(message => {
        // console.log(message);
        this.getDict()
      })
  }
  proceed = () => {
    this.props.history.push('/guidesk/function_time')
  }
  render() {
    const columns =
      [
        {
          Header: 'action',
          Cell: props => {
            return (
              <div>
                <button className='btn btn-danger for_size' onClick={() => this.deleteDict(props.original.id)}>Delete</button>
              </div>
            )
          }
        },
        {
          Header: 'Index',
          accessor: 'Data_Index',
          sortable: false,
          style: {
            textAlign: 'left'
          }
        },
        {
          Header: 'Keyword',
          accessor: 'Keyword',
          filterable: true,
        },
        {
          Header: 'Store Name',
          accessor: 'Store_Name',
        },
        // {
        //   Header: 'City',
        //   accessor: 'address.city'
        // },
      ]
    const form_style = {
      'width': '20%',
      'display': 'inline',
      'margin': '2%',
    }
    return (
      <div>
        <h3>update_store_dict </h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.dictionary_list}
                showPagination={true}
                showPaginationBottom={false}
                showPaginationTop={true}
              >
              </Ktable>
            </div>
            <form onSubmit={this.createDict}>
              <div>
                <InputBox name='func_name' value={this.state.func_name} onChange={this.setTitle} />
                <InputBox name='keyword' value={this.state.keyword} onChange={this.setTitle} />
                <InputBox name='value' value={this.state.value} onChange={this.setTitle} />
              </div>
              <button style={form_style} type="submit" id='' className="btn btn-success" >Enter</button>
            </form>
            <GuiButton value='proceed' action={() => this.proceed()} />
            <span className='text-success'>{this.state.message}</span>
          </div>
          {/* <RightSideTest name='match dictionary' output={this.state.output} action={() => this.dictionary_check()}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default UpdateStoreDictionery
