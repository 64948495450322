import React from 'react';
import axios from 'axios';
import { buttons_mallandtest as buttons } from '../buttons'
import RightSideTest from '../components/rightSideTest';
import GuiButton from '../components/GuiButton';
// import Ktable from '../components/dataTable/K_table'
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader'
import * as config from '../config.json'

class MallAndTest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collection_list: [],
    }
  }
  testText = () => {
  }
  componentDidMount = () => {
    const id = this.props.match.params.name;
    let header = {
      headers: authHeader()
    };
    if (header.headers.Authorization) {
      // let {user} = 'users'
      axios.get(`${config.baseurl}${id}/${config.collections}`, header)
        // .then(res => console.log(res.data.data.collection_list))
        .then(res => (res.data.data.collection_list))
        .then(collection_list => this.setState(
          {
            collection_list: collection_list
          }
        ))
        .catch(error => console.log(error))
    }
    else {
      this.props.history.push('/login')
    }
  }
  render() {
    const columns =
      [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Count',
          accessor: 'count',
        },
      ]
    return (
      <div>
        <h3>{localStorage.getItem('mall_name')}</h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.collection_list}
                showPagination={true}
                showPaginationBottom={false}
                showPaginationTop={true}
              >
              </Ktable>
            </div>
            {
              buttons.map((button) => {
                return <GuiButton key={button.id} value={button.name} action={() => this.props.history.push(`${button.path}`)} />
              })
            }
          </div>
          {/* <RightSideTest name='Test' action={this.testText}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default MallAndTest