import React from 'react';
import { buttons_addMall as buttons } from '../buttons'
import GuiButton from '../components/GuiButton';
class AddNewMall extends React.Component {
  render() {
    return (
      <div style={{ padding: 15 }}>
        <h3>add new mall </h3>
        <div style={{ height: 150, border: '1px solid #000', }}>
          data table
        </div>
        {
          buttons.map((button) => {
            return <GuiButton key={button.id} value={button.name} />
          })
        }
      </div>
    )
  }
}
export default AddNewMall