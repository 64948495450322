import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox';
import GuiButton from '../components/GuiButton';
import * as config from '../config.json'
class FunctionIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        function_list: [],
        output: '',
        Function_Index: '',
        Function_Store_Name: '',
        Main_Store_Name: '',
        loading:true,
        mallId: localStorage.getItem('mall_id'),
    }
  }
  componentDidMount = () => {
    this.getFunction()
  }
  getFunction = () => {
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.function}`, header)
    .then(res => {
        this.setState({function_list: res.data.data.function_list});
        setTimeout(() => {
            this.setState({loading: false})
        }, 1000);
    })
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  time_check = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.append("image_to_text", image_to_text);
    formData.set("mall_id", id);
    formData.set("Function_Index", this.state.Function_Index);
    formData.set("Function_Store_Name", this.state.Function_Store_Name);
    formData.set("Main_Store_Name", this.state.Main_Store_Name);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.time_check}`, formData, header)
      // .then(res => console.log(res.data.data.time_check))
      .then(res => (res.data.data.time_check))
      .then(output => this.setState({
        output: output,
        Function_Index: '',
        Function_Store_Name: '',
        Main_Store_Name: '',
      }))
  }
  proceed = () => {
    this.props.history.push('/guidesk/function_time')
  }
  render() {
    const columns =
      [
        {
          Header: 'ID',
          accessor: 'id',
          sortable: false,
          filterable: true,
        },
        {
            Header: 'Function Index ID',
            accessor: 'Function_Index',
            sortable: true,
            filterable: true,
        },
        {
          Header: 'Shop Name',
          accessor: 'retailerName',
          filterable: true,
        },
        {
          Header: 'Action',
          Cell: props => {
            return (
              <div>
                <button className='btn btn-info for_size' onClick={() => this.props.history.push(`/guidesk/update-function/${props.row.id}`)}>Update</button>
              </div>
            )
          }
        },
      ]
    return (
      <div>
        <div style={{display: 'flex',flexWrap: 'wrap',justifyContent: 'end',}}>
          <h3>Function_data_index</h3>
          <button className="btn btn-info" style={{height:'38px', marginTop: '15px',}} onClick={() => this.props.history.push(`/guidesk/mall/${this.state.mallId}`)}>Go Back</button>
        </div>
        <div style={{ padding: 15, position: "relative" }}>
            <div>
                {
                    this.state.loading
                    ?
                    <div>Loading..</div>
                    :
                    <Ktable
                        columns={columns}
                        data={this.state.function_list}
                        showPagination={true}
                        showPaginationBottom={true}
                        showPaginationTop={false}
                    />
                }
            </div>
        </div>
      </div>
    )
  }
}
export default FunctionIndex;