export const buttons_addMall =
  [
    {
      id: 1,
      name: 'Enter',
    },
    {
      id: 2,
      name: 'Update',
    },
    {
      id: 3,
      name: 'Back To Home',
    },
    {
      id: 4,
      name: 'Proceed',
    },
  ]
export const buttons_mallandtest =
  [
    {
      id: 1,
      name: 'Retailer List',
      path: '/guidesk/retailer-list',
    },
    {
      id: 2,
      name: 'Update Address',
      path: '/guidesk/addresscheck',

    },
    {
      id: 3,
      name: 'Update Dictionary',
      path: '/guidesk/storedictionery'
    },
    {
      id: 4,
      name: 'Update Function',
      path: '/guidesk/function_index',
    },
    {
      id: 5,
      name: 'Invoice Data',
      path: '/guidesk/invoice',
    },
    {
      id: 5,
      name: 'Output Data',
      path: '/guidesk/outputData',
    },
  ]
//  default {buttons_addMall, buttons_mallandtest}