import React, { Component } from 'react'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import './K_table.css'
class K_table extends Component {
  render() {
    const page_size = [100]
    return (
      <div className="table-wrapper">
        <ReactTable
          columns={this.props.columns}
          data={this.props.data}
          filterable={false}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          className='-highlight'
          // pageSizeOptions={page_size}
          minRows={0}
          style={{ textAlign: 'center' }}
          showPagination={this.props.showPagination}
          defaultPageSize={100}
        >
        </ReactTable>
      </div>
    )
  }
}
export default K_table