import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox';
import GuiButton from '../components/GuiButton';
import * as config from '../config.json'
class FunctionDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      function_list: [],
      output: '',
      d_mode: '',
      d_search_mode: '',
      d_search_text: '',
      d_subtract_from_time: '',
      d_add_to_time: '',
      d_regex: '',
      d_selector: '',
      d_day: '',
    }
  }
  componentDidMount = () => {
    this.getFunction()
  }
  getFunction = () => {
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.function}`, header)
    // .then(res => console.log(res.data))
    // .then(res => res.data)
    // .then(function_list => this.setState(
    //   {
    //     function_list: function_list
    //   }
    // ))
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  date_check = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.append("image_to_text", image_to_text);
    formData.set("mall_id", id);
    formData.set("date_mode", this.state.d_mode);
    formData.set("date_search_mode", this.state.d_search_mode);
    formData.set("date_search_text", this.state.d_search_text);
    formData.set("subtract_from_date", this.state.d_subtract_from_time);
    formData.set("add_to_date", this.state.d_add_to_time);
    formData.set("date_regex", this.state.d_regex);
    formData.set("date_selector", this.state.d_selector);
    formData.set("select_date", this.state.d_day);
    formData.set("day", true);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.date_check}`, formData, header)
      // .then(res => console.log(res.data.data.time_check))
      .then(res => (res.data.data.date_check))
      .then(output => this.setState({
        output: output,
        d_mode: '',
        d_search_mode: '',
        d_search_text: '',
        d_subtract_from_time: '',
        d_add_to_time: '',
        d_regex: '',
        d_selector: '',
        d_day: '',
      }))
  }
  proceed = () => {
    this.props.history.push('/guidesk/function_invoice')
  }
  render() {
    const columns =
      [
        {
          Header: 'ID',
          accessor: 'id',
          sortable: false,
          style: {
            textAlign: 'left'
          }
        },
        {
          Header: 'Name',
          accessor: 'name',
          filterable: true,
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'City',
          accessor: 'address.city'
        },
      ]
    return (
      <div>
        <h3>Function_data_date </h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.function_list}
                showPagination={true}
              >
              </Ktable>
            </div>
            <form>
              <div>
                <InputBox name='d_mode' value={this.state.d_mode} onChange={this.setTitle} />
                <InputBox name='d_search_mode' value={this.state.d_search_mode} onChange={this.setTitle} />
                <InputBox name='d_search_text' value={this.state.d_search_text} onChange={this.setTitle} />
                <InputBox name='d_subtract_from_time' value={this.state.d_subtract_from_time} onChange={this.setTitle} />
              </div>
              <div>
                <InputBox name='d_add_to_time' value={this.state.d_add_to_time} onChange={this.setTitle} />
                <InputBox name='d_regex' value={this.state.d_regex} onChange={this.setTitle} />
                <InputBox name='d_selector' value={this.state.d_selector} onChange={this.setTitle} />
                <InputBox name='d_day' value={this.state.d_day} onChange={this.setTitle} />
              </div>
              {/* <button style={form_style} type="submit" id='' className="btn btn-success" >Enter</button> */}
            </form>
            <GuiButton value='proceed' action={() => this.proceed()} />
          </div>
          {/* <RightSideTest name='Run' output={this.state.output} action={() => this.date_check()}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default FunctionDate