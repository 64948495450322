import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox';
import GuiButton from '../components/GuiButton';
import * as config from '../config.json'

class FunctionInvoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      function_list: [],
      output: '',
      i_method: '',
      i_search_text: '',
      i_search_regex: '',
      i_search_regex_2: '',
      i_start_index: '',
      i_end_index: '',
      i_invoice_value: '',
      i_split_on: '',
      i_value_selector: '',
      i_substract_from_index: '',
      i_add_to_index: '',
      i_regex_value_selector: '',
      i_overall_regex_selector: '',
    }
  }
  componentDidMount = () => {
    this.getFunction()
  }
  getFunction = () => {
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.function}`, header)
    // .then(res => console.log(res.data))
    // .then(res => res.data)
    // .then(function_list => this.setState(
    //   {
    //     function_list: function_list
    //   }
    // ))
  }
  setTitle = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  date_check = () => {
    const image_to_text = (localStorage.getItem('image_to_text_data'));
    const id = localStorage.getItem('mall_id')
    let formData = new FormData();
    formData.append("image_to_text", image_to_text);
    formData.set("mall_id", id);
    formData.set("method", this.state.i_method);
    formData.set("search_text", this.state.i_search_text);
    formData.set("search_regex", this.state.i_search_regex);
    formData.set("search_regex_2", this.state.i_search_regex_2);
    formData.set("start_index", this.state.i_start_index);
    formData.set("end_index", this.state.i_end_index);
    formData.set("invoice_value", this.state.i_invoice_value);
    formData.set("split_on", this.state.i_split_on);
    formData.set("value_selector", this.state.i_value_selector);
    formData.set("subtract_from_index", this.state.i_substract_from_index);
    formData.set("add_to_index", this.state.i_add_to_index);
    formData.set("regex_value_selector", this.state.i_regex_value_selector);
    formData.set("overall_regex_selector", this.state.i_overall_regex_selector);
    let header = {
      headers: authHeader()
    };
    axios.post(`${config.baseurl}${config.invoice_number_check}`, formData, header)
      // .then(res => console.log(res.data.data.time_check))
      .then(res => (res.data.data.invoice_number_check))
      .then(output => this.setState({
        output: output,
        i_method: '',
        i_search_text: '',
        i_search_regex: '',
        i_search_regex_2: '',
        i_start_index: '',
        i_end_index: '',
        i_invoice_value: '',
        i_split_on: '',
        i_value_selector: '',
        i_substract_from_index: '',
        i_add_to_index: '',
        i_regex_value_selector: '',
        i_overall_regex_selector: '',
      }))
  }
  proceed = () => {
    this.props.history.push('/guidesk/function_total')
  }
  render() {
    const columns =
      [
        {
          Header: 'ID',
          accessor: 'id',
          sortable: false,
          style: {
            textAlign: 'left'
          }
        },
        {
          Header: 'Name',
          accessor: 'name',
          filterable: true,
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'City',
          accessor: 'address.city'
        },
      ]
    return (
      <div>
        <h3>Function_data_invoice </h3>
        <div style={{ padding: 15, position: "relative" }}>
          <div>
            <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
              <Ktable
                columns={columns}
                data={this.state.function_list}
                showPagination={true}
              >
              </Ktable>
            </div>
            <form>
              <div>
                <InputBox name='i_method' value={this.state.i_method} onChange={this.setTitle} />
                <InputBox name='i_search_text' value={this.state.i_search_text} onChange={this.setTitle} />
                <InputBox name='i_search_regex' value={this.state.i_search_regex} onChange={this.setTitle} />
                <InputBox name='i_search_regex_2' value={this.state.i_search_regex_2} onChange={this.setTitle} />
                <InputBox name='i_start_index' value={this.state.i_start_index} onChange={this.setTitle} />
                <InputBox name='i_end_index' value={this.state.i_end_index} onChange={this.setTitle} />
                <InputBox name='i_invoice_value' value={this.state.i_invoice_value} onChange={this.setTitle} />
                <InputBox name='i_split_on' value={this.state.i_split_on} onChange={this.setTitle} />
              </div>
              <div>
                <InputBox name='i_value_selector' value={this.state.i_value_selector} onChange={this.setTitle} />
                <InputBox name='i_substract_from_index' value={this.state.i_substract_from_index} onChange={this.setTitle} />
                <InputBox name='i_add_to_index' value={this.state.i_add_to_index} onChange={this.setTitle} />
                <InputBox name='i_regex_value_selector' value={this.state.i_regex_value_selector} onChange={this.setTitle} />
              </div>
              <div>
                <InputBox name='i_overall_regex_selector' value={this.state.i_overall_regex_selector} onChange={this.setTitle} />
              </div>
              {/* <button style={form_style} type="submit" id='' className="btn btn-success" >Enter</button> */}
            </form>
            <GuiButton value='proceed' action={() => this.proceed()} />
          </div>
          {/* <RightSideTest name='Run' output={this.state.output} action={() => this.date_check()}></RightSideTest> */}
        </div>
      </div>
    )
  }
}
export default FunctionInvoice