import React from 'react';
import axios from 'axios';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import * as config from '../config.json'

class RetailerList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        function_list: [],
        loading:true,
    }
  }
  componentDidMount = () => {
    this.getFunction()
  }

  getFunction = () => {
    const id = localStorage.getItem('mall_id')
    let header = {
      headers: authHeader()
    };
    axios.get(`${config.baseurl}${id}/${config.function}`, header)
    .then(res => {
        this.setState({function_list: res.data.data.function_list});
        setTimeout(() => {
            this.setState({loading: false})
        }, 1000);
    })
  }

  render() {
    const columns =
      [
        {
          Header: 'ID',
          accessor: 'id',
          sortable: false,
        },
        {
            Header: 'Function Index ID',
            accessor: 'Function_Index',
            sortable: true,
            filterable: true,
        },
        {
          Header: 'Shop Name',
          accessor: 'retailerName',
          filterable: true,
        },
      ]
    return (
      <div>
        <div style={{display: 'flex',flexWrap: 'wrap',justifyContent: 'end',}}>
          <h3>Retailer List</h3>
          <button className="btn btn-info" style={{height:'38px', marginTop: '15px',}} onClick={() => this.props.history.push(`/guidesk/mall/${this.state.mallId}`)}>Go Back</button>
        </div>
        <div style={{ padding: 15, position: "relative" }}>
            <div>
                {
                    this.state.loading
                    ?
                    <div>Loading..</div>
                    :
                    <Ktable
                        columns={columns}
                        data={this.state.function_list}
                        showPagination={true}
                        showPaginationBottom={true}
                        showPaginationTop={false}
                    />
                }
            </div>
        </div>
      </div>
    )
  }
}
export default RetailerList;