import axios from "axios";
import * as config from '../config.json'
async function login(loginCreds) {
  let url = `${config.baseurl}${config.login}`;
  let header = {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=--------------------------688005748556493701532494',
    }
  };
  const response = await axios.post(url, loginCreds, header)
  return response;
}
export const loginService = {
  login,
};