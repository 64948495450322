import React from 'react';
import { Route, Switch, Link } from "react-router-dom";
import { routes_addmall as routes } from '../routes'
class GuiDesk extends React.Component {
  // componentDidMount=()=>this.props.history.push('/guidesk/mallselect')
  logoutUser = () => {
    console.log('inside');
    localStorage.clear();
    this.props.history.push('/login');
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/guidesk/mallselect">OCR GUI</Link>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Link className="nav-item nav-link" to="/guidesk/mallselect">Mall Select</Link>
              {/* <Link className="nav-item nav-link" to="/guidesk">Features</Link> */}
              {/* <Link className="nav-item nav-link" to='/guidesk/addnewmall'>add new mall</Link> */}
            </div>
          </div>
          <div onClick={() => this.logoutUser()}>Logout</div>
        </nav>
        <Switch>
          {
            routes.map((route, index) => {
              return <Route key={index} path={route.path} component={route.component} exact />
            })
          }
        </Switch>
      </div>
    )
  }
}
export default GuiDesk