import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import GuiButton from '../components/GuiButton';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox'
import ReactTable from 'react-table-v6'
import * as config from '../config.json'
import 'react-table-v6/react-table.css'
import './../components/dataTable/K_table.css';

class OutputData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mallId: localStorage.getItem('mall_id'),
            output_list: [],
            message: '',
            output: '',
            currentPage: 0,
            pageSize: 10,
            totalPages: 0
        }
      }
      componentDidMount = () => {
        localStorage.setItem('output', '');
        // this.getAddress()
      }


      // Pagination logic starts here

      onPageChange = (data) => {
        this.setState({
          ...this.state,
          currentPage: data
        })
      }

      onPageSizeChange = (data) => {
        this.setState({
          ...this.state,
          pageSize: data
        }) 
      }

      onFetchData = (state, instance) => {
        console.log(state, instance)
        const id = localStorage.getItem('mall_id')
        // const mall_name = this.props.match.params.name;
        let header = {
          headers: authHeader(),
          params: {
            pageNo: state.page + 1,
            pageSize: state.pageSize,
            sort: 'desc'
          }
        };
        axios.get(`${config.baseurl}${id}/${config.output}`, header)
          .then(res => {
            return res.data.data
          })
          .then( data => {
            this.setState(
              {
                totalPages: data.totalPages,
                output_list: data.output_list,
              }
            )
          })
      }
      // pagination login ends here
      // getAddress = () => {
      //   const id = localStorage.getItem('mall_id')
      //   // const mall_name = this.props.match.params.name;
      //   let header = {
      //     headers: authHeader()
      //   };
      //   axios.get(`${config.baseurl}${id}/${config.output}`, header, )
      //     .then(res => {
      //       return res.data.data
      //     })
      //     .then( data => {
      //       let totalPages = Math.floor(data.noOfRecords / this.state.pageSize);
      //       this.setState(
      //         {
      //           totalPages: totalPages,
      //           output_list: data.output_list
      //         }
      //       )
      //     })
      // }
      setTitle = (e) => {
        this.setState({ [e.target.name]: e.target.value })
      }
      AddressCheck = () => {
        const image_to_text = (localStorage.getItem('image_to_text_data'));
        const id = localStorage.getItem('mall_id')
        let formData = new FormData();
        formData.set("image_to_text", image_to_text);
        formData.set("mall_id", id);
        let header = {
          headers: authHeader()
        };
        axios.post(`${config.baseurl}${config.address_check}`, formData, header)
          // .then(res=>console.log(res.data.data.address_check))
          .then(res => (res.data.data.address_check))
          .then(output => this.setState({
            output: output
          }))
          .catch(error => {
            this.props.history.push('/login')
          })
      }
      deleteAddress = (id) => {
        const mall_id = localStorage.getItem('mall_id')
        let header = {
          headers: authHeader()
        };
        axios.delete(`${config.baseurl}${mall_id}/${config.output}/${id}`, header)
          .then(res => {
            this.getAddress();
          }
          )
          .catch(error => {
            this.props.history.push('/login')
          })
      }

      setOutputData = (data, id) => {
        localStorage.setItem('output', JSON.stringify(data));
        this.props.history.push(`/guidesk/outputData/${id}`)
      }

      render() {
        const columns =
          [
            {
              Header: 'View',
              Cell: props => {
                return (
                  <div>
                    <button className='btn btn-info for_size' onClick={() => this.setOutputData(props.original, props.original.id)}>View</button>
                  </div>
                )
              }
            },
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: 'Store Name',
              accessor: 'store_name',
              sortable: true,
            },
            {
              Header: 'Keyword',
              accessor: 'keyword',
              sortable: true,
            },
            {
              Header: 'Final Error Code',
              accessor: 'final_errorCode',
              sortable: true,
            },
            {
              Header: 'Error Code',
              accessor: 'errorCode',
              sortable: true,
            },
            {
              Header: 'Time Error Code',
              accessor: 'time_errorCode',
              sortable: true,
            },
            {
              Header: 'Bill Date',
              accessor: 'bill_date',
              sortable: true,
            },
            {
              Header: 'Bill Time',
              accessor: 'bill_time',
              sortable: true,
            },
            {
              Header: 'Bill Number',
              accessor: 'bill_number',
              sortable: true,
            },
            {
              Header: 'Bill Total',
              accessor: 'bill_total',
              sortable: true,
            },
            {
              Header: 'Date Error Code',
              accessor: 'date_errorCode',
              sortable: true,
            },
            {
              Header: 'Invoice Number Error Code',
              accessor: 'invoice_number_errorCode',
              sortable: true,
            },
            {
              Header: 'Total Error Code',
              accessor: 'total_errorCode',
              sortable: true,
            },
            {
              Header: 'Img file to text time',
              accessor: 'img_file_to_text_time',
              sortable: true,
            },
            {
              Header: 'Logic Time',
              accessor: 'logic_time',
              sortable: true,
            },
            {
              Header: 'Database gcp cleaning time',
              accessor: 'database_gcp_cleaning_time',
              sortable: true,
            },
            {
              Header: 'Database time',
              accessor: 'database_time',
              sortable: true,
            },
            {
              Header: 'File Location',
              accessor: 'file_location',
              sortable: true,
            },
          ]
        const form_style = {
          'width': '20%',
          'display': 'inline',
          'margin': '2%',
        }
        return (
          <div>
            <h3>Output Data</h3>
            <div style={{ padding: 15, position: "relative" }}>
              <div>
                <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
                <div className="table-wrapper">
                    <ReactTable
                      columns={columns}
                      data={this.state.output_list}
                      pages={this.state.totalPages}
                      filterable={false}
                      showPaginationTop={true}
                      showPaginationBottom={false}
                      className='-highlight'
                      // pageSizeOptions={page_size}
                      minRows={0}
                      manual
                      style={{ textAlign: 'center' }}
                      showPagination={true}
                      onPageChange={this.onPageChange}
                      onPageSizeChange={this.onPageSizeChange}
                      defaultPageSize={this.defaultPageSize}
                      onFetchData={this.onFetchData}
                      >
                    </ReactTable>
                  </div>
                </div>
                <span className='text-success'>{this.state.message}</span>
                <button style={{marginTop: '3%',}} className="btn btn-info" onClick={() => this.props.history.push(`/guidesk/mall/${this.state.mallId}`)}>Go Back</button>
              </div>
              {/* <RightSideTest name='address check' output={this.state.output} action={() => this.AddressCheck()}></RightSideTest> */}
            </div>
          </div>
        )
      }
}
export default OutputData