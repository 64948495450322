import React from 'react';
import axios from 'axios';
import RightSideTest from '../components/rightSideTest';
import GuiButton from '../components/GuiButton';
import Ktable from '../components/dataTable/K_table'
import authHeader from '../services/authHeader';
import InputBox from '../components/inputBox'
import * as config from '../config.json'

class InvoiceNumber extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mallId: localStorage.getItem('mall_id'),
            invoice_number_list: [],
            message: '',
            output: '',
        }
      }
      componentDidMount = () => {
        this.getAddress()
      }
      getAddress = () => {
        const id = localStorage.getItem('mall_id')
        // const mall_name = this.props.match.params.name;
        let header = {
          headers: authHeader()
        };
        axios.get(`${config.baseurl}${id}/${config.invoice}`, header)
          .then(res => (res.data.data.invoice_number_list))
          .then(invoice_number_list => this.setState(
            {
              invoice_number_list: invoice_number_list
            }
          ))
      }
      setTitle = (e) => {
        this.setState({ [e.target.name]: e.target.value })
      }
      AddressCheck = () => {
        const image_to_text = (localStorage.getItem('image_to_text_data'));
        const id = localStorage.getItem('mall_id')
        let formData = new FormData();
        formData.set("image_to_text", image_to_text);
        formData.set("mall_id", id);
        let header = {
          headers: authHeader()
        };
        axios.post(`${config.baseurl}${config.address_check}`, formData, header)
          // .then(res=>console.log(res.data.data.address_check))
          .then(res => (res.data.data.address_check))
          .then(output => this.setState({
            output: output
          }))
          .catch(error => {
            this.props.history.push('/login')
          })
      }
      deleteAddress = (id) => {
        const mall_id = localStorage.getItem('mall_id')
        let header = {
          headers: authHeader()
        };
        axios.delete(`${config.baseurl}${mall_id}/${config.invoice}/${id}`, header)
          .then(res => {
            this.getAddress();
          }
          )
          .catch(error => {
            this.props.history.push('/login')
          })
      }
      render() {
        const columns =
          [
            {
              Header: 'ID',
              accessor: 'id',
              filterable: true,
            },
            {
              Header: 'Store Name',
              accessor: 'Invoice_Store_Name',
              sortable: false,
              filterable: true,
            },
            {
              Header: 'Invoice No.',
              accessor: 'Invoice_Number',
              filterable: true,
            },
            {
              Header: '',
              Cell: props => {
                return (
                  <div>
                    <button style={{display:'none'}} className='btn btn-danger for_size' onClick={() => this.deleteAddress(props.original.id)}>Delete</button>
                  </div>
                )
              }
            },
          ]
        const form_style = {
          'width': '20%',
          'display': 'inline',
          'margin': '2%',
        }
        return (
          <div>
            <h3>Invoice Data</h3>
            <div style={{ padding: 15, position: "relative" }}>
              <div>
                <div style={{ height: 300, border: '1px solid #000', overflow: 'scroll' }}>
                  <Ktable
                    columns={columns}
                    data={this.state.invoice_number_list}
                    showPagination={true}
                    showPaginationBottom={false}
                    showPaginationTop={true}
                  >
                  </Ktable>
                </div>
                <span className='text-success'>{this.state.message}</span>
                <button style={{marginTop: '3%',}} className="btn btn-info" onClick={() => this.props.history.push(`/guidesk/mall/${this.state.mallId}`)}>Go Back</button>
              </div>
              {/* <RightSideTest name='address check' output={this.state.output} action={() => this.AddressCheck()}></RightSideTest> */}
            </div>
          </div>
        )
      }
}
export default InvoiceNumber