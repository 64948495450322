import GuiDesk from './pages/guiDesk'
import MallSelect from './pages/mallSelect'
import MallAndTest from './pages/mallandTest'
import AddressCheck from './pages/addressCheck'
import UpdateAddress from './pages/updateAdress'
import StoreDictionery from './pages/storeDictionery'
import UpdateStoreDictionery from './pages/updateStoreDict'
import FunctionTime from './pages/functionTime'
import FunctionDate from './pages/functionDate'
import FunctionInvoice from './pages/functionInvoice'
import FunctionTotal from './pages/functionTotal'
import AddNewMall from './pages/addNewMall'
import FunctionIndex from './pages/functionIndex'
import EditFunctionData from './pages/editFunctionData';
import RetailerList from './pages/retailerList'
import InvoiceNumber from './pages/invoiceNumber'
import OutputData from './pages/outputData'
import OutputDataDetails from './pages/outputDataDetails'
export const routes_addmall = [
  {
    path: '/guidesk',
    component: GuiDesk
  },
  {
    path: '/guidesk/mallselect',
    component: MallSelect
  },
  {
    path: '/guidesk/addnewmall',
    component: AddNewMall
  },
  {
    path: '/guidesk/mall/:name',
    component: MallAndTest
  },
  {
    path: '/guidesk/addresscheck',
    component: AddressCheck
  },
  {
    path: '/guidesk/updateaddress/:id',
    component: UpdateAddress
  },
  {
    path: '/guidesk/storedictionery',
    component: StoreDictionery
  },
  {
    path: '/guidesk/updatestoredictionery',
    component: UpdateStoreDictionery
  },
  {
    path: '/guidesk/function_time',
    component: FunctionTime
  },
  {
    path: '/guidesk/function_index',
    component: FunctionIndex
  },
  {
    path: '/guidesk/function_date',
    component: FunctionDate
  }, 
  {
    path: '/guidesk/function_invoice',
    component: FunctionInvoice
  }, 
  {
    path: '/guidesk/function_total',
    component: FunctionTotal
  }, 
  {
    path: '/guidesk/update-function/:functionId',
    component: EditFunctionData
  },
  {
    path: '/guidesk/retailer-list',
    component: RetailerList
  },
  {
    path: '/guidesk/invoice',
    component: InvoiceNumber
  },
  {
    path: '/guidesk/outputData',
    component: OutputData
  },
  {
    path: '/guidesk/outputData/:outputId',
    component: OutputDataDetails
  },
]
