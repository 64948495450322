
import React from 'react';
import axios from 'axios'
import * as config from '../config.json'
class RightSideTest extends React.Component {
  constructor() {
    super()
    this.state = {
      input: "",
      selectedFile: null,
    }
  }
  componentDidMount = () => {
    let image_to_text_data = (localStorage.getItem('image_to_text_data'));
    if (image_to_text_data) {
      this.setState({ input: image_to_text_data })
    }
  }
  selectedFile = (e) => {
    this.setState({
      selectedFile: e.target.files[0]
    },
      this.handleUpload
    )
  }
  handleUpload = () => {
    this.setState({ input: '' })
    const formData = new FormData();
    formData.append('image', this.state.selectedFile)
    axios.post(`${config.baseurl}${config.image_to_text}`, formData, {
      headers: {
        'Authorization': localStorage.getItem('user')
      },
    }
    )
      .then(res => (res.data.data.image_to_text_data))
      .then(image_to_text_data => {
        this.setState({ input: JSON.stringify(image_to_text_data) })
        localStorage.setItem('image_to_text_data', JSON.stringify(image_to_text_data))
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  textareaChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    );
  }
  // convertToText=()=>{
  //   console.log('convertToText')
  //   //function will be provide by the sahil
  // }
  render() {
    const textArea = {
      display: 'block',
      margin: 15,
      padding: 15,
      width: '90%',
      resize: 'none'
    }
    return (
      <div style={{ position: "absolute", top: 0, left: '70%', width: '30%', height: 500, textAlign: 'center' }}>
        {/* {this.state.selectedFile} */}
        <textarea name="input" id="" value={this.state.input} style={textArea}
          onChange={this.textareaChange} rows="10" placeholder='image or cleaned text' readOnly></textarea>
        <label className='btn btn-info' htmlFor="selectedFile" style={{ margin: 10, display: 'block', width: '90%' }} >upload_convert_to_text  </label>
        <input type="file" id="selectedFile" onChange={this.selectedFile} style={{ display: 'none' }} />
        {/* <button className='btn btn-info' onClick={this.convertToText} style={{ margin: 10, display: 'block', width: '90%' }} >image to text</button> */}
        <button className='btn btn-info' style={{ margin: 10, display: 'block', width: '90%' }} onClick={this.props.action}>{this.props.name}</button>
        <textarea name="output" id="" value={this.props.output} style={textArea}
          onChange={this.textareaChange} rows="3" placeholder='result' readOnly ></textarea>
      </div>
    )
  }
}
export default RightSideTest